<template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-alert v-if="notification !== ''"  dense text :type="`${actionClass}`" class="mt-5" >
                  {{ notification }}
                 </v-alert>
                <v-form  ref="form" v-model="valid" lazy-validation >
                    <v-row no-gutters class="ma-0">
                        <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="mr-13">
                            <label for="">Name</label>
                            <v-text-field
                            v-model="name"
                            :rules="nameRules"
                            required
                            outlined
                            dense
                            ></v-text-field>
                        </v-col>
                         <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="mr-13">
                            <label for="">Username</label>
                            <v-text-field
                            v-model="username"
                            :rules="usernameRules"
                            required
                            outlined
                            dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="12" md="12 " sm="12" xs="12" >
                            <label for="">E-mail</label>
                            <v-text-field
                            v-model="email"
                            :rules="emailRules"
                            required
                            outlined
                            dense
                            readonly
                            ></v-text-field>
                        </v-col>
                         <v-card-actions dark>
                            <v-spacer></v-spacer>
                             <v-btn  color="blue darken-1" @click="cancel" dark>
                                Cancel
                            </v-btn>

                            <v-btn  :disabled="!valid || loading" :color="`${zamuYellow3}`"  class="mr-4 zamu-sub-heading "  @click="save" dark >
                            Submit
                            <v-progress-circular
                                indeterminate
                                color="primary"
                                class="ml-4"
                                :size="20"
                                v-if="loading"
                                ></v-progress-circular>
                            </v-btn>
                         </v-card-actions>

                    </v-row>

                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
</template>

<script>
import coreMixin from '@/mixins/coreMixin';
import colorMixin from '@/mixins/colorMixin';


export default {
    name: 'EditUser',
    mixins: [coreMixin, colorMixin],
     data () {
       return {
            user: null,
            name: '',
            username: '',
            email: '',
            notification: '',
            actionClass: '',
            formTitle: 'Edit User',
             valid: false,
             loading: false,
             userID: null,
       };
     },


    async mounted() {
       const id = this.$route.params.id;
       this.userID = id;
        this.user = await this.retrieveUser(id);
        this.name = this.user.name;
        this.username = this.user.username;
        this.email = this.user.email;

    },
    methods: {
        async retrieveUser(id) {
              const endpoint = `api/user/${id}`;

              const fullPayload = {
                  app: this.apiUrl,
                  endpoint,
              };

              try {
                  const response = await this.axiosGetRequest(fullPayload);
                  const user = response.status === 200 ? response.data : null;
                  return user;
              } catch (error) {
                return null;
              }
          },
          async save(){
              const formvalid = this.$refs.form.validate();
              if(!formvalid) {
                  return;
              }

                this.loading = true;

                    const payload = {
                          name: this.name,
                          email: this.email,
                          username: this.username,
                      };
                      const fullPayload = {
                          app: this.apiUrl,
                          params: payload,
                          endpoint: `auth/update/${this.userID}`,
                      };
                        try {
                          const res = await this.axiosPutRequest(fullPayload);
                          this.loading = false;
                          this.notification = res.data ? 'User updated successful' : 'User failed to update';
                          this.actionClass = res.data  ? 'success' : 'error';
                       

                      } catch (error) {
                          this.notification = 'User failed to update. Contact the admin';
                          this.actionClass = 'error';
                          this.loading = false;
                          return error.response;
                      }

          },
          cancel () {
           this.$router.push('/admin/users');

          },
        }

}
</script>

<style>

</style>